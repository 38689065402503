export interface Tool {
  title: string;
  link: string;
  icon: string;
  pages?: Pages;
}

export interface MainTool extends Tool {
  redirect: Tool;
}

export interface Sidenav {
  maintool: MainTool;
  tools: Tool[];
}

export interface Pages {
  add?: Tool;
}

export interface SelectedTool {
  mainTool: MainTool;
  tool: Tool;
}

const createAddPageTool = (link = '/add'): Tool => ({
  title: 'Add',
  link,
  icon: 'add',
});

export const homeTools = new Map<string, Tool>([
  [
    'dashboard',
    {
      title: 'Dashboard',
      link: '',
      icon: 'bar_chart',
    },
  ],
]);

export const lomtTools = new Map<string, Tool>([
  [
    'schedule',
    {
      title: 'Schedule',
      link: '/schedule',
      icon: 'schedule',
      pages: {
        add: createAddPageTool('/schedule/add'),
      },
    },
  ],
  [
    'abtest',
    {
      title: 'A/B Test',
      link: '/ab',
      icon: 'call_split',
      pages: {
        add: createAddPageTool('/ab/add'),
      },
    },
  ],
  [
    'contents',
    {
      title: 'Contents',
      link: '/contents',
      icon: 'list',
      pages: {
        add: createAddPageTool('/contents/add'),
      },
    },
  ],
  [
    'segments',
    {
      title: 'Segments',
      link: '/segments',
      icon: 'group',
      pages: {
        add: createAddPageTool('/segments/add'),
      },
    },
  ],
  [
    'contentType',
    {
      title: 'Content Type',
      link: '/contenttypes',
      icon: 'assignment',
      pages: {
        add: createAddPageTool('/contenttypes/add'),
      },
    },
  ],
  [
    'environment',
    {
      title: 'Environments',
      link: '/environments',
      icon: 'computer',
      pages: {
        add: createAddPageTool('/environments/add'),
      },
    },
  ],
  [
    'usersets',
    {
      title: 'User Sets',
      link: '/usersets',
      icon: 'recent_actors',
      pages: {
        add: createAddPageTool('/usersets/add'),
      },
    },
  ],
  [
    'tags',
    {
      title: 'Tags',
      link: '/tags',
      icon: 'label',
      pages: {
        add: createAddPageTool('/tags/add'),
      },
    },
  ],
]);

export const pnTools = new Map<string, Tool>([
  [
    'push',
    {
      title: 'Push',
      link: '/push/list',
      icon: 'sms',
      pages: {
        add: createAddPageTool('/push/add'),
      },
    },
  ],
  [
    'template',
    {
      title: 'Push Planner',
      link: '/push-template/list',
      icon: 'date_range',
      pages: {
        add: createAddPageTool('/push-template/add'),
      },
    },
  ],
  [
    'token',
    {
      title: 'User Token Management',
      link: '/user/token',
      icon: 'person',
    },
  ],
]);

export const xpromoTools = new Map<string, Tool>([
  [
    'xpromo',
    {
      title: 'Cross Promotions',
      link: '/list',
      icon: 'list',
      pages: {
        add: createAddPageTool('/add'),
      },
    },
  ],
]);

export const cmTools = new Map<string, Tool>([
  [
    'editor',
    {
      title: 'Editor',
      link: '/editor',
      icon: 'insert_drive_file',
    },
  ],
  [
    'validator',
    {
      title: 'Validator',
      link: '/validator',
      icon: 'playlist_add_check',
    },
  ],
  [
    'branch',
    {
      title: 'Branch',
      link: '/branch',
      icon: 'folder',
    },
  ],
  [
    'merge',
    {
      title: 'Merge (classic)',
      link: '/merge',
      icon: 'merge_type',
    },
  ],
  [
    'merge-v2',
    {
      title: 'Merge (conflict resolver)',
      link: '/merge-v2',
      icon: 'merge_type',
    },
  ],
]);

export const paymentTools = new Map<string, Tool>([
  [
    'search',
    {
      title: 'Search',
      link: '/search',
      icon: 'search',
    },
  ],
]);

export const dynamicEnvironmentTools = new Map<string, Tool>([
  [
    'dynamic-environment',
    {
      title: 'Environments',
      link: '/list',
      icon: 'computer',
      pages: {
        add: createAddPageTool('/add'),
      },
    },
  ],
]);

export const deployTools = new Map<string, Tool>([
  [
    'deploy',
    {
      title: 'Deploy',
      link: '/list',
      icon: 'present_to_all',
      pages: {
        add: createAddPageTool('/add'),
      },
    },
  ],
]);

export const tools = new Map<string, Map<string, Tool>>([
  ['home', homeTools],
  ['lomt', lomtTools],
  ['pn', pnTools],
  ['xpromo', xpromoTools],
  ['payment', paymentTools],
  ['cm', cmTools],
  ['dynamic-environment', dynamicEnvironmentTools],
  ['deploy', deployTools],
]);

export const mainTools = new Map<string, MainTool>([
  [
    'home',
    {
      title: 'Home',
      link: '/projects/%PROJECT%/home',
      icon: 'home',
      redirect: homeTools.get('dashboard'),
    },
  ],
  [
    'lomt',
    {
      title: 'Live Operations',
      link: '/projects/%PROJECT%/tools/lomt',
      icon: 'whatshot',
      redirect: lomtTools.get('schedule'),
    },
  ],
  [
    'pn',
    {
      title: 'Push Notification',
      link: '/projects/%PROJECT%/tools/push-notification',
      icon: 'message',
      redirect: pnTools.get('push'),
    },
  ],
  [
    'xpromo',
    {
      title: 'Cross Promotion',
      link: '/projects/%PROJECT%/tools/xpromo',
      icon: 'shuffle',
      redirect: xpromoTools.get('xpromo'),
    },
  ],
  [
    'payment',
    {
      title: 'Payment Validation',
      link: '/projects/%PROJECT%/tools/payment',
      icon: 'credit_card',
      redirect: paymentTools.get('search'),
    },
  ],
  [
    'cm',
    {
      title: 'Config Manager',
      link: '/projects/%PROJECT%/tools/config-manager',
      icon: 'folder_open',
      redirect: cmTools.get('editor'),
    },
  ],
  [
    'dynamic-environment',
    {
      title: 'Dynamic Environment',
      link: '/projects/%PROJECT%/tools/dynamic-environment',
      icon: 'computer',
      redirect: dynamicEnvironmentTools.get('dynamic-environment'),
    },
  ],
  [
    'deploy',
    {
      title: 'Deploy Manager',
      link: '/projects/%PROJECT%/tools/deploy',
      icon: 'present_to_all',
      redirect: deployTools.get('deploy'),
    },
  ],
]);

export const sidenavData: Sidenav[] = [
  {
    maintool: mainTools.get('home'),
    tools: [homeTools.get('dashboard')],
  },
  {
    maintool: mainTools.get('lomt'),
    tools: [
      lomtTools.get('schedule'),
      lomtTools.get('abtest'),
      lomtTools.get('contents'),
      lomtTools.get('segments'),
      lomtTools.get('usersets'),
      lomtTools.get('contentType'),
      lomtTools.get('tags'),
      lomtTools.get('environment'),
    ],
  },
  {
    maintool: mainTools.get('pn'),
    tools: [pnTools.get('push'), pnTools.get('template'), pnTools.get('token')],
  },
  {
    maintool: mainTools.get('xpromo'),
    tools: [xpromoTools.get('xpromo')],
  },
  {
    maintool: mainTools.get('payment'),
    tools: [paymentTools.get('search')],
  },
  {
    maintool: mainTools.get('cm'),
    tools: [
      cmTools.get('branch'),
      cmTools.get('editor'),
      cmTools.get('validator'),
      cmTools.get('merge'),
      cmTools.get('merge-v2'),
    ],
  },
  {
    maintool: mainTools.get('dynamic-environment'),
    tools: [dynamicEnvironmentTools.get('dynamic-environment')],
  },
  {
    maintool: mainTools.get('deploy'),
    tools: [deployTools.get('deploy')],
  },
];
